import React, { useContext, useState, useEffect } from "react";
import { useSelectorMenuTree } from "./useSelectorMenuTree";
import ModalContext from "../../../context/ModalContext";

//assets
import { TripleMaze } from "react-spinner-animated";
import "./index.css";

export const SelectorMenuTree = ({ ruta, title, filter, id = [] }) => {
    const {
        data,
        loading,
        allColumns,
        messageAlert,
        objSelected,
        setObjSelected,
        aplyChanges,
        saveChanges,
        defaultSetting,
        busqueda,
        setBusqueda,
        filterResult,
    } = useSelectorMenuTree(ruta, filter, id);

    const { select, setSelect } = useContext(ModalContext);

    useEffect(() => {
        data.filter((f) => {
            if (f.id === select) {
                setObjSelected(f);
            }
        });
    }, [data]);

    const getNodesWithParentId = (data, idPadreKey = 0) => {
        return data
            .filter((node) => node.idPadreKey === idPadreKey)
            .map((node) => {
                return {
                    ...node,
                    children: getNodesWithParentId(data, node.id),
                };
            });
    };
    var parseData = getNodesWithParentId(data);

    return (
        <div className="flex flex-col w-full px-6 h-full">
            <div className="inline-flex items-center justify-between mb-5">
                <h1 className="text-4xl tracking-tight font-semibold text-gray-800">
                    {title}
                </h1>
                <div className="relative rounded-md justify-end w-1/2 mx-auto">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </div>
                    <input
                        type="search"
                        value={busqueda}
                        onChange={(e) => setBusqueda(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Búsqueda inteligente"
                    />
                </div>
            </div>
            <h4 className="text-sm leading-7">
                <span className="font-bold">
                    {objSelected === null
                        ? "Aún no seleccionó ningún item"
                        : objSelected.Nombre &&
                          "Usted seleccionó : " +
                              JSON.stringify(objSelected.Nombre)}
                </span>
            </h4>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden md:rounded-lg"></div>
                    <div className="max-h-full overflow-hidden overflow-y-visible border border-gray-100 rounded-lg p-4 ">
                        <div className="h-80">
                            <ul className="ul-tree">
                                {loading ? (
                                    <div className="h-40">
                                        <TripleMaze
                                            centered={true}
                                            height={"250px"}
                                            text={"Cargando..."}
                                        />
                                    </div>
                                ) : busqueda === "" ? (
                                    parseData.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            item={item}
                                            select={select}
                                            setSelect={setSelect}
                                            objSelected={setObjSelected}
                                        />
                                    ))
                                ) : filterResult.length > 0 ? (
                                    filterResult.map((item) => (
                                        <li
                                            key={item.id}
                                            className={`li-tree hover:bg-gray-100 ${
                                                item.id == select
                                                    ? "bg-gray-500 hover:bg-gray-500"
                                                    : ""
                                            }`}
                                        >
                                            <button
                                                className={`ml-2 hover:underline ${
                                                    item.id == select &&
                                                    "hover:no-underline"
                                                }`}
                                                onClick={() =>
                                                    setSelect(item.id) &
                                                    setObjSelected(item)
                                                }
                                            >
                                                {item.Nombre}
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <div className="text-center pt-8 text-2xl text-gray-600">
                                        No se encontraron resultados para su búqueda
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ item, select, setSelect, objSelected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = item.children && item.children.length > 0;

    function handleClick() {
        setIsOpen(!isOpen);
    }

    const selectItem = (item) => {
        setSelect(item.id);
        objSelected(item);
    };

    return (
        <li
            className={`li-tree hover:bg-gray-100 ${
                item.id == select ? "bg-gray-500 hover:bg-gray-500" : ""
            }`}
        >
            {hasChildren && (
                <i
                    onClick={handleClick}
                    className="js-toggle-icon hover:bg-gray-300 hover:text-black"
                >
                    +
                </i>
            )}
            <button
                className={`ml-2 hover:underline ${
                    item.id == select && "hover:no-underline"
                }`}
                onClick={() => selectItem(item)}
            >
                {item.Nombre}
            </button>
            {hasChildren && (
                <ul
                    className="ul-tree"
                    style={{ display: isOpen ? "block" : "none" }}
                >
                    {item.children.map((child) => (
                        <MenuItem
                            key={child.id}
                            item={child}
                            select={select}
                            setSelect={setSelect}
                            objSelected={objSelected}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

const MenuItemList = ({item, }) => {

}
