import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../context/UserContext";
import { Alert } from "../../Alerts";

export const ViewModalParteOperativo = ({ id, ruta }) => {
    const [open, setOpen] = useState(false);
    const { url, headers, user, workstationActive } = useContext(UserContext);
    const [data, setData] = useState([]);

    const openViewModal = async () => {
        await axios
            .post(url + ruta + "/read/" + id, {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
            })
            .then(function (response) {
                if (response.status === 200) {
                    //console.log(response.data);
                    setOpen(true);
                    setData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const [currentTab, setCurrentTab] = useState("1");

    const tabs = [
        {
            id: 1,
            tabTitle: "tab 1",
            title: "Datos del parte operativo",
        },
        {
            id: 2,
            tabTitle: "tab 2",
            title: "Artículos",
        },
        {
            id: 3,
            tabTitle: "tab 3",
            title: "Características",
        },
        {
            id: 4,
            tabTitle: "tab 4",
            title: "Datos adicionales",
        },
        {
            id: 5,
            tabTitle: "tab 5",
            title: "Gestión",
        },
    ];

    const handleTabClick = ({ target }) => {
        const { id } = target;
        setCurrentTab(id);
    };

    useEffect(() => {
        if (!open) {
            setCurrentTab("1");
        }
    }, [open]);

    return (
        <>
            <div className="tooltip">
                <button
                    className="inline-flex items-center "
                    onClick={openViewModal}
                >
                    <span className="tooltiptext">Ver</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-indigo-600 hover:text-indigo-900"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </button>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-5/6 sm:p-6">
                                    <div className="absolute top-0 right-0 hidden pt-5 pr-6 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full text-center">
                                        <p className="text-medium font-bold leading-7 text-gray-800 text-center">
                                            Parte operativo Nº{" "}
                                            {data.Numero}
                                        </p>
                                    </div>
                                    <Alert />
                                    <div className="hidden sm:block pt-5">
                                        <div className="border-b border-gray-200">
                                            <nav
                                                className="-mb-px flex"
                                                aria-label="Tabs"
                                            >
                                                {tabs.map((tab, i) => (
                                                    <button
                                                        key={i}
                                                        id={tab.id}
                                                        className={`w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                                            currentTab ===
                                                            `${tab.id}`
                                                                ? "border-indigo-500 text-indigo-600"
                                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                                        }`}
                                                        onClick={handleTabClick}
                                                    >
                                                        {tab.title}
                                                    </button>
                                                ))}
                                            </nav>
                                        </div>

                                        <div className="content">
                                            {(() => {
                                                if (currentTab === "1") {
                                                    return (
                                                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                <dl className="grid grid-cols-6 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-semibold text-gray-500">
                                                                            Cliente
                                                                        </dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            {data.Cliente ===
                                                                            null
                                                                                ? ""
                                                                                : data.Cliente &&
                                                                                  data
                                                                                      .Cliente
                                                                                      .RazonSocial}
                                                                        </dd>
                                                                    </div>

                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-semibold text-gray-500">
                                                                            Contrato
                                                                        </dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            {data.ContratoOperacionKey ===
                                                                            null
                                                                                ? ""
                                                                                : data.ContratoOperacion &&
                                                                                  data
                                                                                      .ContratoOperacion
                                                                                      .Nombre}
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                                <hr className="my-2" />
                                                                <dl className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-semibold text-gray-500">
                                                                            Fecha
                                                                            vto
                                                                        </dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            {data.FechaVencimientoParte ===
                                                                            null
                                                                                ? ""
                                                                                : data.FechaVencimientoParte &&
                                                                                  Moment(
                                                                                      data.FechaVencimientoParte
                                                                                  ).format(
                                                                                      "DD-MM-YYYY"
                                                                                  )}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-semibold text-gray-500">
                                                                            Sector
                                                                        </dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">
                                                                            {data.Sector ===
                                                                            null
                                                                                ? ""
                                                                                : data.Sector &&
                                                                                  data
                                                                                      .Sector
                                                                                      .Nombre}
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    );
                                                } else if (currentTab === "2") {
                                                    return (
                                                        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                        >
                                                                            Cant.
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                                        >
                                                                            Nombre
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                        >
                                                                            Unitario
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                        >
                                                                            Dto.
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                                                        >
                                                                            P.Total
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.Items.map(
                                                                        (
                                                                            item,
                                                                            i
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className="border-b border-gray-200"
                                                                            >
                                                                                <td className="mt-0.5 text-gray-800 pl-4">
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .Cantidad
                                                                                    }
                                                                                </td>
                                                                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                                                    <div className="font-medium text-gray-900">
                                                                                        {
                                                                                            item
                                                                                                .ItemOperacion
                                                                                                .Item
                                                                                                .Nombre
                                                                                        }
                                                                                    </div>
                                                                                    <div className="mt-0.5 text-gray-800 sm:hidden">
                                                                                        {
                                                                                            item
                                                                                                .ItemOperacion
                                                                                                .Cantidad
                                                                                        }{" "}
                                                                                        cant
                                                                                        item
                                                                                        por{" "}
                                                                                        {
                                                                                            item
                                                                                                .ItemOperacion
                                                                                                .PrecioUnitarioFinal
                                                                                        }{" "}
                                                                                        con
                                                                                        dto.
                                                                                        de{" "}
                                                                                        {
                                                                                            item
                                                                                                .ItemOperacion
                                                                                                .PorcentajeDescuento
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-800 sm:table-cell">
                                                                                    {item.ItemOperacion.PrecioUnitarioFinal.toLocaleString(
                                                                                        "en-US",
                                                                                        {
                                                                                            style: "currency",
                                                                                            currency:
                                                                                                "USD",
                                                                                        }
                                                                                    )}
                                                                                </td>
                                                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-800 sm:table-cell">
                                                                                    %{" "}
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PorcentajeDescuento
                                                                                    }
                                                                                </td>
                                                                                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                                    {item.ItemOperacion.PrecioTotalFinal.toLocaleString(
                                                                                        "en-US",
                                                                                        {
                                                                                            style: "currency",
                                                                                            currency:
                                                                                                "USD",
                                                                                        }
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={
                                                                                4
                                                                            }
                                                                            className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                        >
                                                                            Subtotal
                                                                        </th>
                                                                        <th
                                                                            scope="row"
                                                                            className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                        >
                                                                            Subtotal
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                            {data.Operacion.Subtotal.toLocaleString(
                                                                                "en-US",
                                                                                {
                                                                                    style: "currency",
                                                                                    currency:
                                                                                        "USD",
                                                                                }
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={
                                                                                4
                                                                            }
                                                                            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                        >
                                                                            Monto
                                                                            IVA
                                                                        </th>
                                                                        <th
                                                                            scope="row"
                                                                            className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                        >
                                                                            Monto
                                                                            IVA
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                            {data.Operacion.MontoIVA.toLocaleString(
                                                                                "en-US",
                                                                                {
                                                                                    style: "currency",
                                                                                    currency:
                                                                                        "USD",
                                                                                }
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={
                                                                                4
                                                                            }
                                                                            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                                        >
                                                                            Total
                                                                        </th>
                                                                        <th
                                                                            scope="row"
                                                                            className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                                                                        >
                                                                            Total
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                                                            {data.Operacion.ImporteTotal.toLocaleString(
                                                                                "en-US",
                                                                                {
                                                                                    style: "currency",
                                                                                    currency:
                                                                                        "USD",
                                                                                }
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    );
                                                } else if (currentTab === "3") {
                                                    return (
                                                        <>
                                                            {data.Operacion.Caracteristicas.map(
                                                                (c, i) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                i
                                                                            }
                                                                            className="border-b border-gray-200 bg-white px-4 py-2 sm:px-6"
                                                                        >
                                                                            <h4 className="text-base font-medium leading-6 text-gray-900">
                                                                                {
                                                                                    c.Caracteristica
                                                                                }
                                                                            </h4>
                                                                            <p className="mt-1 text-sm text-gray-500">
                                                                                {
                                                                                    c.Nombre
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <div>
                                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Notas
                                                                    públicas
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-600 sm:col-span-2 sm:mt-0 italic">
                                                                    {data
                                                                        .Operacion
                                                                        .Notas ===
                                                                    ""
                                                                        ? "No tiene notas públicas"
                                                                        : JSON.stringify(
                                                                              data
                                                                                  .Operacion
                                                                                  .Notas
                                                                          )}
                                                                </dd>
                                                            </div>
                                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Notas
                                                                    privadas
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-600 sm:col-span-2 sm:mt-0 italic">
                                                                    {data.NotasPrivadas ===
                                                                    ""
                                                                        ? "No tiene notas privadas"
                                                                        : JSON.stringify(
                                                                              data.NotasPrivadas
                                                                          )}
                                                                </dd>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(!open)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
