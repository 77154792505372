import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import axios from "../../api/axios";
import UserContext from "../../context/UserContext";
import { DndContext, closestCenter } from "@dnd-kit/core"
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

//
import { ColumnaOrden } from "../ElementsUi/ColumnaOrden";
import { ColumnaDistribucion } from "../ElementsUi/ColumnaDistribucion";
import { ColumnaVisibilidad } from "../ElementsUi/ColumnaVisibilidad";

export const TableFormat = ({
    allColumns,
    messageAlert,
    columnsDirection = [], 
    ruta,
    onApplyOrderColumn,
    onApplyChanges,
    onSaveChanges,
    onDefaultWebForm,
}) => {
    const [open, setOpen] = useState(false);
    const tabs = [
        { id: 1, name: "Visibilidad" },
        { id: 2, name: "Distribución" },
        { id: 3, name: "Ordenamiento" }
    ];
    const [activeTab, setActiveTab] = useState(1);
    const [checkbox, setCheckbox] = useState([]);
    const [array, setArray] = useState([]);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { user, headers, workstationActive, url } = useContext(UserContext);

    useEffect(() => {
        const sortedData = allColumns.slice().sort((a, b) => a.Orden - b.Orden);
        setCheckbox(sortedData);
    }, [allColumns])

    useEffect(() => {
      setActiveTab(1)
    }, [open])

    
    const handleOnChange = (e) => {
        let updateCheckbox = checkbox;

        updateCheckbox = updateCheckbox
            .filter((item) => item.WebFormDefaultKey == e.target.value)
            .map((item) => (item.Visible = e.target.checked));

        setArray(updateCheckbox);
    };

    const aplyChanges = () => {
        const isAnyColumnCheck = checkbox.map((col) => {
            if(col.Visible === false){
                return false;
            }else{
                return true;
            }
        })
        if(isAnyColumnCheck.includes(true)){
            onApplyChanges(checkbox);
        }else{
            setErrorMessage("Debe seleccionar una columna para mostrar");
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
        }

    };

    const saveChanges = () => {
        onSaveChanges(checkbox);
        
        if(messageAlert){
            setMessage("Se guardaron las columnas");
            setTimeout(() => {
                setMessage("");
            }, 2000);
        }
    };

    const defaultColumns = async() => {
        await axios
            .post(url + `webformusuario/update_default/${ruta}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
            })
            .then(function (response) {
                setMessage("Se reestablecieron las columnas");
                setTimeout(() => {
                    setMessage("");
                }, 2000);
                setCheckbox(response.data);
                onDefaultWebForm(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        // console.log("active", active.id);
        // console.log("over", over.id);

        if (!active.id !== over.id) {
            setCheckbox((checkbox) => {
                const oldIndex = checkbox.findIndex(
                    (columna) => columna.WebFormDefaultKey === active.id
                );
                const newIndex = checkbox.findIndex(
                    (columna) => columna.WebFormDefaultKey === over.id
                );

                // console.log(arrayMove(checkbox, oldIndex, newIndex));
                return arrayMove(checkbox, oldIndex, newIndex);
            });
        }

        // console.log("drag end");
    };

    const columnasDefaultParse = columnsDirection.map((colDefault) => {
        const coincidencia = checkbox.find(
            (columna) => columna.FormFieldName === colDefault.fieldname
        );

        if (coincidencia) {
            return {
                ...colDefault,
                FormFieldTitle: coincidencia.FormFieldTitle,
            };
        }

        return colDefault;
    });

    const handleColumnsToOrder = (e) => {
        onApplyOrderColumn(e);
    }
    const handleOrden = () => {
        
    }

    return (
        <>
            <button
                onClick={() => setOpen(!open)}
                type="button"
                className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-700"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--mdi h-7 w-7 mr-2"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M3 3h14a2 2 0 0 1 2 2v7.08a6.01 6.01 0 0 0-4.32.92H11v4h1.08c-.11.68-.11 1.35 0 2H3a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2m0 4v4h6V7H3m8 0v4h6V7h-6m-8 6v4h6v-4H3m19.78 6.32l-1.07-.82c.02-.17.04-.33.04-.5s-.01-.33-.04-.5l1.06-.82a.26.26 0 0 0 .06-.32l-1-1.73c-.06-.13-.19-.13-.33-.13l-1.22.5c-.28-.18-.54-.35-.85-.47l-.19-1.32c-.01-.12-.12-.21-.24-.21h-2c-.12 0-.23.09-.25.21l-.19 1.32c-.3.13-.59.29-.85.47l-1.24-.5c-.11 0-.24 0-.31.13l-1 1.73c-.06.11-.04.24.06.32l1.06.82a4.193 4.193 0 0 0 0 1l-1.06.82a.26.26 0 0 0-.06.32l1 1.73c.06.13.19.13.31.13l1.24-.5c.26.18.54.35.85.47l.19 1.32c.02.12.12.21.25.21h2c.12 0 .23-.09.25-.21l.19-1.32c.3-.13.56-.29.84-.47l1.22.5c.14 0 .27 0 .34-.13l1-1.73a.26.26 0 0 0-.06-.32M18 19.5c-.83 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5s1.5.67 1.5 1.5s-.66 1.5-1.5 1.5Z"
                    ></path>
                </svg>
                Formato de tabla
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <div className="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll pt-6 pb-2">
                                                <div className="px-4 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title className="text-2xl font-bold text-gray-900">
                                                            Columnas disponibles
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                                onClick={() =>
                                                                    setOpen(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <span className="sr-only">
                                                                    Close panel
                                                                </span>
                                                                <XIcon
                                                                    className="h-6 w-6"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block px-5">
                                                    <div className="border-b border-gray-200">
                                                        <nav
                                                            className="-mb-px flex"
                                                            aria-label="Tabs"
                                                        >
                                                            {tabs.map((tab) => (
                                                                <button
                                                                    key={
                                                                        tab.name
                                                                    }
                                                                    onClick={() =>
                                                                        setActiveTab(
                                                                            tab.id
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        tab.id ==
                                                                        activeTab
                                                                            ? "border-indigo-500 text-indigo-600"
                                                                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                                                    }
                                                                        w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium
                                                                    `}
                                                                >
                                                                    {tab.name}
                                                                </button>
                                                            ))}
                                                        </nav>
                                                    </div>
                                                </div>
                                                {errorMessage && (
                                                    <div className="w-full bg-red-500 py-2">
                                                        <p className="text-center text-white">
                                                            {errorMessage}
                                                        </p>
                                                    </div>
                                                )}
                                                {activeTab === 1 ? (
                                                    <div className="relative mt-6 flex-1 px-4 sm:px-6 h-full">
                                                        <p className="text-sm text-gray-700 text-justify">
                                                            Elija las columnas
                                                            que se van a
                                                            visualizar.
                                                        </p>
                                                        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                                            {checkbox.map(
                                                                (columna) => (
                                                                    <ColumnaVisibilidad
                                                                        columna={
                                                                            columna
                                                                        }
                                                                        key={
                                                                            columna.WebFormDefaultKey
                                                                        }
                                                                        handleOnChange={
                                                                            handleOnChange
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : activeTab === 2 ? (
                                                    <div className="relative mt-6 flex-1 px-4 sm:px-6 h-full">
                                                        <p className="text-sm text-gray-700 text-justify">
                                                            Elija el orden que
                                                            quiere visualizar
                                                            las columnas, haga
                                                            click sobre la que
                                                            quiera cambiar y
                                                            arrastre hasta la
                                                            posición deseada.
                                                        </p>
                                                        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                                            <DndContext
                                                                collisionDetection={
                                                                    closestCenter
                                                                }
                                                                onDragEnd={
                                                                    handleDragEnd
                                                                }
                                                            >
                                                                <SortableContext
                                                                    items={
                                                                        checkbox
                                                                    }
                                                                    strategy={
                                                                        verticalListSortingStrategy
                                                                    }
                                                                >
                                                                    {checkbox.map(
                                                                        (
                                                                            columna
                                                                        ) => (
                                                                            <ColumnaDistribucion
                                                                                key={
                                                                                    columna.WebFormDefaultKey
                                                                                }
                                                                                columna={
                                                                                    columna
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </SortableContext>
                                                            </DndContext>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    activeTab === 3 && (
                                                        <div className="relative mt-6 flex-1 px-4 sm:px-6 h-full">
                                                            <p className="text-sm text-gray-700 text-justify">
                                                                Este es el orden
                                                                con que se
                                                                muestran los
                                                                datos. Si quiere
                                                                modificarlo,
                                                                haga click en
                                                                "modificar orden
                                                                columnas"
                                                            </p>
                                                            <div className="my-4">
                                                                <ColumnaOrden
                                                                    columnas={
                                                                        checkbox
                                                                    }
                                                                    columnasDefault={
                                                                        columnsDirection
                                                                    }
                                                                    onHandleToOrder={handleColumnsToOrder}
                                                                />
                                                            </div>
                                                            <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                                                {columnasDefaultParse.map(
                                                                    (
                                                                        colDefault,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="inline-flex items-center py-3 w-full justify-between"
                                                                        >
                                                                            <label
                                                                                htmlFor={`col-${colDefault.fieldname}`}
                                                                                className="text-sm text-gray-700"
                                                                            >
                                                                                {
                                                                                    colDefault.FormFieldTitle
                                                                                }
                                                                            </label>
                                                                            <button>
                                                                                {colDefault.fieldtype ===
                                                                                "desc" ? (
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className="h-6 w-6"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M19 9l-7 7-7-7"
                                                                                        />
                                                                                    </svg>
                                                                                ) : (
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className="h-6 w-6"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M5 15l7-7 7 7"
                                                                                        />
                                                                                    </svg>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {message && (
                                                    <div
                                                        className={`${
                                                            messageAlert
                                                                ? "alert-shown"
                                                                : "alert-hidden"
                                                        }`}
                                                    >
                                                        <p className="text-white bg-green-500 px-4 py-2 text-center">
                                                            {message}
                                                        </p>
                                                    </div>
                                                )}

                                                <div className="flex flex-shrink-0 justify-end px-4 mt-2 pb-4">
                                                    <button
                                                        type="submit"
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-sky-600 shadow-xl hover:bg-blue-800 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={aplyChanges}
                                                    >
                                                        Aplicar
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={saveChanges}
                                                    >
                                                        Guardar cambios
                                                    </button>
                                                </div>
                                                <div className="w-full px-4 pb-2 text-end">
                                                    <button
                                                        className="text-blue-600 hover:underline text-sm"
                                                        onClick={defaultColumns}
                                                    >
                                                        Reestablecer columnas
                                                        por default
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};