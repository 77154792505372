import { useRef, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import ModalContext from "../../../context/ModalContext";
import axios from "axios";
import AlertContext from "../../../context/AlertContext";

export const useSelectorArticulo = (onAddArticle, listaPrecioKey = 1) => {
    const { user, url, workstationActive, headers } = useContext(UserContext);
    const { select, setSelect } = useContext(ModalContext);
    const { setResponseText, setStatusCode } = useContext(AlertContext);
    const [open, setOpen] = useState(false);
    const [alerta, setAlerta] = useState("");
    const [articulos, setArticulos] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [loading, setLoading] = useState(false);
    const [allColumns, setAllColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const cancelButtonRef = useRef(null);
    const filterResult = articulos.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    const getArticulos = async () => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + "articulolista/readall", {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto:true
            })
            .then(function (response) {
                console.log(response);
                setLoading(false);
                setArticulos(response.data.Data);
                setAllColumns(response.data.WebForms)
                setSelect([]);
            })
            .catch(function (error) {
                console.log(error.response);
               if (error.response != undefined) {
                   setLoading(false);
                   setArticulos([]);
                   if (error.response.status === 404) {
                       setResponseText(
                           "Error de petición. Consulte con administración"
                       );
                   } else {
                       setResponseText(error.response.data.Motivo);
                   }
                   setStatusCode(error.response.status);
               } else {
                   setLoading(false);
                   setArticulos([]);
                   setResponseText(
                       "Hubo un error crítico. Consulte con administración"
                   );
               }
            });
    };

    const selectArticle = () => {
        if (select.length === 0) {
            setAlerta("Debe seleccionar un item al menos.");
        } else {
            setOpen(false);
            onAddArticle(select);
        }
    };

    const close = () => {
        setOpen(!open);
        setBusqueda("");
    }

    // const searchValue = async (e) => {
    //     e.preventDefault();
    //     if (search.trim() === "") return getArticulos();

    //     setLoading(true);
    //     await axios
    //         .get(url + `articulolista/allFilter/Filtered=${search}`, {
    //             headers: headers,
    //         })
    //         .then(function (response) {
    //             setArticulos(response.data);
    //             setLoading(false);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };

    return {
        open,
        alerta,
        busqueda, 
        setBusqueda,
        setOpen,
        loading,
        setAlerta,
        allColumns,
        getArticulos,
        selectArticle,
        cancelButtonRef,
        sortConfig,
        setSortConfig,
        filterResult,
        close
    };
};
