import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ModalContext from "../../context/ModalContext";
import UserContext from "../../context/UserContext";
import axios from "axios";

export default function Selector({ content, ruta, action, textButton }) {
    const [open, setOpen] = useState(false);

    const { user, workstationActive, url } = useContext(UserContext);
    const { select, setSelect, data, setData } = useContext(ModalContext);
    const cancelButtonRef = useRef(null);

    const peticion = async() => {
            // let objSelect = JSON.parse(select);
            // console.log(ruta, data);
            await axios.post(url + ruta + action + select,
                    {
                        Workstation: workstationActive.WorkstationKey,
                        Usuario: user.UsuarioKey,
                        [ruta]: data,
                    }
                    //,{   headers: headers, }
                )
                .then(function (response) {
                    setData(response.data);
                    setOpen(false);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
    };

    const cancelPeticion = () => {
        setOpen(false);
        setSelect([]);
    };

    return (
        <>
            {/* <button className="inline-flex items-center text-sm font-medium rounded-md text-blue-600 hover:underline ">
                {ruta}
            </button> */}
            <button
                onClick={() => setOpen(!open)}
                className="inline-flex items-center text-sm font-medium text-blue-600 hover:underline"
            >
                {textButton}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                </svg>
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-5/6 sm:p-6">
                                    <div>{content}</div>
                                    <div className="mt-5 sm:mt-6 flex items-center justify-between">
                                        <button
                                            type="button"
                                            className="ml-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700  sm:col-start-1 sm:text-sm"
                                            onClick={cancelPeticion}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 bg-blue-600 hover:bg-blue-800 text-base font-medium text-white"
                                            onClick={peticion}
                                        >
                                            Seleccionar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
