export const filterReducer = ( initialState = [], action = {}) => {

    switch (action.type) {
        case "addOrUpdateFilter":
            let foundFlag = false;

            let stateAdd = initialState.map((item, index) => {
                if (item.filterName === action.payload.filterName) {
                    foundFlag = true;
                    return action.payload;
                } else {
                    return item;
                }
            });

            if (!foundFlag) {
                stateAdd = [...initialState, action.payload];
            }   

            return stateAdd;

        case "deleteFilter":
            // const stateDelete = initialState.filter(
            //     (item) => item.filterName != action.payload.filterName
            // );
            const stateDelete = initialState.map((item) => {
                if (item.filterName === action.payload.filterName) {
                    return { ...item, action: "delete" };
                }
                return item;
            });

            return stateDelete;

        case "resetAllFilters":
            return [];

        case "clearFilter":
            const filters = initialState.filter(
                (item) => item.filterName != action.payload.filterName
                );
            return filters;

        case "applyFilters":
            console.log(initialState);
            const filtersAplied = initialState.map((item) => {
                if(item.displayvalue != ''){
                    return {...item, aplicado:true}
                }else{
                    return {...item, aplicado: false}
                }
            })
            console.log(filtersAplied);

            case 'validateFilters':
                const validateFilters = initialState.filter((newFilter) => {
                    return newFilter.displayvalue !== '';
                });
                return validateFilters;
        default:
            return initialState;
    }
        

}