import { useContext, useState } from "react";
import Moment from "moment";
import Selector from "../../../Selector/Selector";
import { SelectorGenerico } from "../../../Selector";
import ModalContext from "../../../../context/ModalContext";
import AlertContext from "../../../../context/AlertContext";
import { ConfirmationModal } from "../../../Modales/Confirmation/ConfirmationModal";
import { useActions } from "../../../../permissions/useActions";

export const FirstComponent = ({ ruta, nextStep }) => {
    const { data, setData } = useContext(ModalContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [fechaParte, setFechaParte] = useState("");

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleValidation = () => {
        var validation = false;

        if (data.Cliente === null) {
            setStatusCode(406);
            setResponseText("Debe seleccionar un cliente para continuar.");
            setTimeout(() => {
                setResponseText("");
            }, 3000);
        } else {
            if (data.ContratoOperacion !== null) {
                validation = true;
            } else {
                setStatusCode(406);
                setResponseText("Debe seleccionar un contrato para continuar.");
                setTimeout(() => {
                    setResponseText("");
                }, 3000);
            }
        }

        if (validation) {
            nextStep();
        }
    };

    const handleFechaParte = ({ target }) => {
        const { name, value } = target;
        setFechaParte(value);
        if (data.Items.length !== 0) {
            setOpenConfirmationModal(true);
        } else {
            setData({
                ...data,
                [name]: Moment(value).format("YYYY-MM-DDTHH:mm:ss"),
            });
        }
    };

    const confirmFechaParte = () => {
        const name = "FechaParte";
        setData({
            ...data,
            Items: [],
            [name]: Moment(fechaParte).format("YYYY-MM-DDTHH:mm:ss"),
        });

        setOpenConfirmationModal(false);
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-end w-full">
                    <button
                        className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                        onClick={handleValidation}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 ml-16">
                <div className="pt-8 pl-8 space-y-6">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="first-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Cliente:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"cliente"}
                                        view={"single"}
                                        title={"Seleccione cliente"}
                                        id={
                                            data.Cliente !== null &&
                                            data.Cliente &&
                                            data.Cliente.ClienteKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Cliente === null
                                        ? "Sin seleccion"
                                        : data.Cliente &&
                                          JSON.stringify(
                                              data.Cliente.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_client/"}
                            />
                        </div>
                    </div>
                    {data.Cliente !== null && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="first-name"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Contrato:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"contratooperacion"}
                                            view={"single"}
                                            title={"Seleccione contrato"}
                                            filter={
                                                data.Cliente &&
                                                "cliente/" +
                                                    JSON.stringify(
                                                        data.Cliente.ClienteKey
                                                    )
                                            }
                                            id={
                                                data.ContratoOperacion !==
                                                    null &&
                                                data.ContratoOperacion &&
                                                data.ContratoOperacionKey
                                            }
                                            extraArgument={`/Cliente/${JSON.stringify(
                                                data.Cliente.ClienteKey
                                            )}`}
                                        />
                                    }
                                    textButton={`${
                                        data.ContratoOperacionKey === 0
                                            ? "Sin seleccion"
                                            : data.ContratoOperacion &&
                                              JSON.stringify(
                                                  data.ContratoOperacion.Nombre
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_contract/"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Unidad de negocio:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"unidadnegocio"}
                                        view={"single"}
                                        title={"Seleccione unidad de negocio"}
                                        id={
                                            data.UnidadNegocio !== null &&
                                            data.UnidadNegocio &&
                                            data.UnidadNegocio.UnidadNegocioKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.UnidadNegocio === null
                                        ? "Sin seleccion"
                                        : data.UnidadNegocio &&
                                          JSON.stringify(
                                              data.UnidadNegocio.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_business_unit/"}
                            />
                        </div>
                    </div>
                    {/*<div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Condicion Pago:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"condicionpago"}
                                        view={"single"}
                                        title={"Seleccione condición pago"}
                                    />
                                }
                                textButton={`${
                                    data.CondicionPago === null
                                        ? "Sin seleccion"
                                        : data.CondicionPago &&
                                          JSON.stringify(
                                              data.CondicionPago
                                                  .Nombre
                                          )
                                }`}
                                rutaDefault={
                                    ruta + "/to_assign_payment_condition/"
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Lista precio:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"listaprecio"}
                                        view={"single"}
                                        title={"Seleccione lista precio"}
                                    />
                                }
                                textButton={`${
                                    data.Cliente === null
                                        ? "Sin seleccion"
                                        : data.Cliente &&
                                          JSON.stringify(
                                              data.Cliente
                                                  .ListaPreciosNombre
                                          )
                                }`}
                                rutaDefault={ruta + "/to_assign_price_list/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center pb-1">
                        <label
                            htmlFor="country"
                            className="block text-xs  font-semibold text-gray-900 mr-2"
                        >
                            Prioridad:
                        </label>
                        <div>
                            <select
                                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-xs h-8"
                                name="prioridad"
                                onChange={handleChange}
                                value={data.Prioridad}
                            >
                                <option value={1}>Baja</option>
                                <option value={2}>Media</option>
                                <option value={3}>Alta</option>
                            </select>
                        </div>
                    </div> */}
                </div>
                <div className="space-y-4 pt-8">
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaParte"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha Parte.:
                        </label>
                        <input
                            type="date"
                            name="FechaParte"
                            value={Moment(data.FechaParte).format("yyyy-MM-DD")}
                            onChange={handleFechaParte}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>

                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaParte"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha vto.:
                        </label>
                        <input
                            type="date"
                            name="FechaVencimiento"
                            value={Moment(data.FechaVencimientoParte).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    {/* <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaOrdenCompra"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha:
                        </label>
                        <input
                            type="date"
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            name="FechaOrdenCompra"
                            value={Moment(data.FechaOrdenCompra).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaEntrega"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha entr:
                        </label>
                        <input
                            type="date"
                            name="FechaEntrega"
                            value={Moment(data.FechaEntrega).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    
                    {data.Numero !== "" && (
                        <p className="block text-xs  font-semibold text-gray-900">
                            Número:
                            <span className="ml-4 font-semibold text-gray-700">
                                {data.Numero &&
                                    data.Numero}
                            </span>
                        </p>
                    )}
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="NumeroEntidad"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Número OC cliente:{" "}
                        </label>
                        <input
                            type="text"
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            name="NumeroEntidad"
                            value={data.NumeroEntidad}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="depositoentrega"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Deposito entrega:
                        </label>
                        <div className="ml-4 text-gray-700 text-xs">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"deposito"}
                                        view={"single"}
                                        title={"Seleccione depósito"}
                                    />
                                }
                                textButton={`${
                                    data.DepositoEntregaCodigo ===
                                    null
                                        ? "Sin seleccion"
                                        : data.DepositoEntregaCodigo &&
                                          JSON.stringify(
                                              data.DepositoEntregaCodigo
                                          )
                                }`}
                                rutaDefault={
                                    ruta + "/to_assign_delivery_deposit/"
                                }
                            />
                        </div>
                    </div> */}

                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Sector:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"sector"}
                                        view={"single"}
                                        title={"Seleccione sector"}
                                        id={
                                            data.Sector !== null &&
                                            data.Sector &&
                                            data.Sector.SectorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Sector === null
                                        ? "Sin seleccion"
                                        : data.Sector &&
                                          JSON.stringify(data.Sector.Nombre)
                                }`}
                                ruta={ruta}
                                action={"/to_assign_sector/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Zona:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"zona"}
                                        view={"single"}
                                        title={"Seleccione zona"}
                                        id={
                                            data.Zona !== null &&
                                            data.Zona &&
                                            data.Zona.ZonaKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Zona === null
                                        ? "Sin seleccion"
                                        : data.Zona &&
                                          JSON.stringify(data.Zona.Nombre)
                                }`}
                                ruta={ruta}
                                action={"/to_assign_zone/"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {openConfirmationModal && (
                <ConfirmationModal
                    action={"Cambiar fecha parte operativo"}
                    text={
                        "Está a punto de cambiar la fecha del parte operativo. Esta acción va a elminar todos los items de dicho parte."
                    }
                    executeFunction={confirmFechaParte}
                    openConfirmationModal={openConfirmationModal}
                    closeConfirmationModal={() =>
                        setOpenConfirmationModal(false)
                    }
                />
            )}
        </>
    );
};
