//react
import { useState } from "react";

//custom hook
import { useOrdenCompraCliente } from "./useOrdenCompraCliente";

//components
import { Alert } from "../../Alerts/Alert";
import { Table, TableFormat } from "../../TableComponent";
import { EditModalOrdenCompra } from "../../Modales/Edit";

//assets
import { TripleMaze } from "react-spinner-animated";

import "react-spinner-animated/dist/index.css";
import FilterContext from "../../../context/FilterContext";
import { useActions } from "../../../permissions/useActions";
import { GenericFilter } from "../../Filters/GenericFilter";

export const OrdenCompraCliente = () => {
    const {
        loading,
        allColumns,
        orderColumn,
        messageAlert,
        rutaOperacion,
        ordenCompraClienteData,
        getOrdenCompraClienteData,
        applyChanges,
        saveChanges,
        defaultSetting,
        queryObj,
        dispatchFilterReducer,
        filtros,
        acciones,
        applyFilters,
        closeFiltro,
        sortConfig,
        setSortConfig,
        applyOrderColumn,
    } = useOrdenCompraCliente();
    const { isAllowed } = useActions(acciones);

    const [busqueda, setBusqueda] = useState("");
    const filterResult = ordenCompraClienteData.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    return (
        <>
            <div className="w-full px-4 py-2 bg-white overflow-auto">
                <Alert />
                <div className="flex justify-around">
                    <h2 className="w-full text-center text-2xl text-gray-800 font-semibold mt-1.5">
                        Listado orden de compra de cliente
                    </h2>
                </div>
                <div className="flex justify-between py-3">
                    {/*  */}
                    <div className="inline-flex items-center space-x-2">
                        {isAllowed("OPERACION_ORDEN_COMPRA_CLIENTE_NUEVA") && (
                            <EditModalOrdenCompra
                                ruta={rutaOperacion}
                                id={null}
                                refresh={getOrdenCompraClienteData}
                                acciones={acciones}
                                tipo={"cliente"}
                            />
                        )}
                        <TableFormat
                            allColumns={allColumns}
                            messageAlert={messageAlert}
                            ruta={rutaOperacion}
                            columnsDirection={orderColumn}
                            onApplyOrderColumn={applyOrderColumn}
                            onApplyChanges={applyChanges}
                            onSaveChanges={saveChanges}
                            onDefaultWebForm={defaultSetting}
                        />
                        <FilterContext.Provider
                            value={{
                                queryObj,
                                dispatchFilterReducer,
                            }}
                        >
                            <GenericFilter
                                data={filtros}
                                getFiltersData={applyFilters}
                                closeFiltro={closeFiltro}
                            />
                        </FilterContext.Provider>
                    </div>
                    <div className="relative mt-2 rounded-md shadow-sm justify-end">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            value={busqueda}
                            onChange={(e) => setBusqueda(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Búsqueda inteligente"
                        />
                    </div>
                </div>
                {loading ? (
                    <TripleMaze
                        centered={true}
                        width={"350px"}
                        height={"350px"}
                        text={"Cargando..."}
                    />
                ) : filterResult ? (
                    <Table
                        data={filterResult}
                        column={allColumns}
                        view={"grid"}
                        ruta={rutaOperacion}
                        modalEdit={"ordencompracliente"}
                        modalView={"ordencompracliente"}
                        refresh={getOrdenCompraClienteData}
                        acciones={acciones}
                        sortConfig={sortConfig}
                        setSortConfig={setSortConfig}
                        busqueda={busqueda}
                    />
                ) : (
                    <div className="w-full py-4 px-2 mt-20">
                        <p className="text-gray-400 italic text-center">
                            No hay ordenes de compra para esta fecha
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};
