export const urlEncode = (host) => {
    let url = "";
    switch (host) {
        case "badia":
            return url = "https://demo.pwcloud.com.ar/";
    
        case "demo": 
            return url = "https://pwrad.com.ar:35989/";

        default:
            // return url = "http://192.168.0.15:35988/";
            return url = "https://pwrad.com.ar:35989/";
    }
}

