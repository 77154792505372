import { useContext } from "react";
import UserContext from "../../context/UserContext";
import logo from "../../assets/img/pw.png";
import { FilterBySelector } from "../Filters/FilterBySelector";

export const WelcomePage = () => {
    const { user, workstationActive } = useContext(UserContext);

    return (
        <>
            <div className="w-full mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-4xl font-bold text-black text-center">
                    Bienvenido {user.Descripcion}
                </h1>
                <br />
                <h4 className="text-2xl font-semibold text-gray-800 text-center">
                    Trabajando en estación de trabajo :{" "}
                    {workstationActive.Nombre}
                </h4>
                <div className="flex-col w-full items-center text-center justify-center">
                    <p className="italic text-gray-600 text-sm">
                        Deposito: {workstationActive.Deposito.Nombre}
                    </p>
                    <p className="italic text-gray-600 text-sm">
                        Ubicación física:{" "}
                        {workstationActive.Deposito.UbicacionFisica}
                    </p>
                    <p className="italic text-gray-600 text-sm">
                        Código: {workstationActive.Deposito.Codigo}
                    </p>
                </div>
                <hr />
                <br />
                <div className="inline-flex justify-center items-center w-full">
                    <img src={logo} alt="logo" className="App-logo w-2/5" />
                </div>
            </div>
        </>
    );
};
