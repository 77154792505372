import React from 'react';

export const PrecioFormateado = ({ precio, moneda }) => {
  const precioFormateado = precio.toLocaleString('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function existMoneda (moneda) {
    if(moneda && "Simbolo" in moneda){
      return moneda.Simbolo
    }else{
      return "";
    }
  }
  
  return <span>{existMoneda(moneda) + precioFormateado}</span>;
};
