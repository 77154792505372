import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';

export const useLogout = () => {
    const { setUser, setWorkstationActive, setVersionDb, setResourceCache, resourceCache } = useContext(UserContext);
    const navigate = useNavigate();

    const logout = () => {
        setUser(null);
        setWorkstationActive(null);
        setVersionDb("");
        setResourceCache("");
        localStorage.removeItem("user");
        localStorage.removeItem("workstation");
        localStorage.removeItem("version");
        JSON.parse(localStorage.getItem('resourceCache')).map((item) => {
            localStorage.removeItem(item);
        });
        localStorage.removeItem("resourceCache");
        
        navigate("/", {
            replace: true,
        });
    }

    return {logout}
}