import { useContext, useRef, useState, useEffect } from "react";
import Moment from "moment";
import Selector from "../../../Selector/Selector";
import { SelectorGenerico } from "../../../Selector";
import ModalContext from "../../../../context/ModalContext";
import { useActions } from "../../../../permissions/useActions";
import AlertContext from "../../../../context/AlertContext";

import { SelectorByMoneda } from "../../../Selector/SelectorByMoneda/SelectorByMoneda";

export const FirstComponent = ({ ruta, nextStep }) => {
    const { data, setData } = useContext(ModalContext);
    const { isAllowed } = useActions();
    const [monedaSelected, setMonedaSelected] = useState(false)
    const cancelButtonRef = useRef(null)
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const handleChange = ({ target }) => {
        const { name, value, type } = target;
        if (type == "date") {
            setData({
                ...data,
                [name]: Moment(value).format("YYYY-MM-DDTHH:mm:ss"),
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };
    
    useEffect(() => {
        if(data.Operacion.Moneda.EquivalenciaPesos != 1){
            setMonedaSelected(true)
        }
    }, [data.Operacion.Moneda])
    

    const handleChangeCurrency = (newData) => {
        setData(newData);
    }

    const handleValidation = () => {
        if (data.Cliente === null) {
            setStatusCode(406);
            setResponseText("Debe seleccionar un cliente para continuar.");
            setTimeout(() => {
                setResponseText("");
            }, 3000);
        }

        else{
            nextStep();
        }
    };
    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-end w-full">
                    <button
                        className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                        onClick={handleValidation}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 max-h-60 h-60 ml-32">
                <div className="h-48 pt-4 space-y-4">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="first-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Cliente:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"cliente"}
                                        view={"single"}
                                        title={"Seleccione cliente"}
                                        id={
                                            data.Cliente !== null &&
                                            data.Cliente &&
                                            data.Cliente.ClienteKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Cliente === null
                                        ? "Sin seleccion"
                                        : data.Cliente &&
                                          JSON.stringify(
                                              data.Cliente.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_client/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Vendedor:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"vendedor"}
                                        view={"single"}
                                        title={"Seleccione vendedor"}
                                        id={
                                            data.Vendedor !== null &&
                                            data.Vendedor &&
                                            data.Vendedor.VendedorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Vendedor === null
                                        ? "Sin seleccion"
                                        : data.Vendedor &&
                                          JSON.stringify(
                                              data.Vendedor.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            />
                        </div>
                    </div>
                    {isAllowed(
                        "OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_CONDICIONPAGO"
                    ) && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Condicion Pago:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"condicionpago"}
                                            view={"single"}
                                            title={"Seleccione condición pago"}
                                            id={
                                                data.CondicionPago !== null &&
                                                data.CondicionPago &&
                                                data.CondicionPago
                                                    .CondicionPagoKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.CondicionPago === null
                                            ? "Sin seleccion"
                                            : data.CondicionPago &&
                                              JSON.stringify(
                                                  data.CondicionPago.Nombre
                                              )
                                    }`} //
                                    ruta={ruta}
                                    action={"/to_assign_payment_condition/"}
                                />
                            </div>
                        </div>
                    )}
                    {isAllowed(
                        "OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_LISTAPRECIOS"
                    ) && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Lista precio:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"listaprecio"}
                                            view={"single"}
                                            title={"Seleccione lista precio"}
                                            id={
                                                data.Operacion.ListaPrecios !==
                                                    null &&
                                                data.Operacion.ListaPrecios
                                                    .ListaPreciosKey &&
                                                data.Operacion.ListaPrecios
                                                    .ListaPreciosKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.Operacion.ListaPrecios === null
                                            ? "Sin seleccion"
                                            : data.Operacion.ListaPrecios &&
                                              JSON.stringify(
                                                  data.Operacion.ListaPrecios
                                                      .Nombre
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_price_list/"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="w-full inline-flex items-center pb-1">
                        <label
                            htmlFor="country"
                            className="block text-xs  font-semibold text-gray-900 mr-2"
                        >
                            Prioridad:
                        </label>
                        <div>
                            <select
                                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-xs h-8"
                                name="Prioridad"
                                onChange={handleChange}
                                value={data.Prioridad}
                            >
                                <option value={3}>Baja</option>
                                <option value={2}>Media</option>
                                <option value={1}>Alta</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="h-48 pt-4 space-y-4">
                    {isAllowed(
                        "OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_FECHA"
                    ) && (
                        <div className="inline-flex items-center w-full">
                            <label
                                htmlFor="FechaPresupuesto"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Fecha:
                            </label>
                            <input
                                type="date"
                                className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                                name="FechaPresupuesto"
                                value={Moment(data.FechaPresupuesto).format(
                                    "yyyy-MM-DD"
                                )}
                                onChange={handleChange}
                            />
                        </div>
                    )}
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaEntrega"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Entrega:
                        </label>
                        <input
                            type="date"
                            name="FechaEntrega"
                            value={Moment(data.FechaEntrega).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    {isAllowed(
                        "OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_FECHA_VENCIMIENTO"
                    ) && (
                        <div className="inline-flex items-center w-full">
                            <label
                                htmlFor="FechaVencimiento"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Vencimiento:
                            </label>
                            <input
                                type="date"
                                name="FechaVencimiento"
                                value={Moment(data.FechaVencimiento).format(
                                    "yyyy-MM-DD"
                                )}
                                onChange={handleChange}
                                className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            />
                        </div>
                    )}
                    {data.Numero !== "" && (
                        <p className="block text-xs  font-semibold text-gray-900">
                            Número:
                            <span className="ml-4 font-semibold text-gray-700">
                                {data.Numero && data.Numero}
                            </span>
                        </p>
                    )}
                    {isAllowed(
                        "OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_CENTROCOSTO"
                    ) && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Centro de Costo:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"centrocosto"}
                                            view={"single"}
                                            title={
                                                "Seleccione el Centro de Costo"
                                            }
                                            id={
                                                data.CentroCosto !== null &&
                                                data.CentroCosto &&
                                                data.CentroCosto.CentroCostoKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.CentroCosto === null
                                            ? "Sin seleccion"
                                            : data.CentroCosto &&
                                              JSON.stringify(
                                                  data.CentroCosto.Nombre
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_payment_condition/"}
                                />
                            </div>
                        </div>
                    )}
                    {/* {isAllowed("OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_MONEDA") && (
                    )} */}
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Moneda:
                        </label>
                        <div className="ml-4">
                            <SelectorByMoneda
                                textButton={`${
                                    data.Operacion.Moneda === null
                                        ? "Sin seleccion"
                                        : data.Operacion.Moneda &&
                                          JSON.stringify(
                                              data.Operacion.Moneda.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_currency/"}
                                onHandleChange={handleChangeCurrency}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};