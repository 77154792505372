import { Fragment, useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import { OptionsItemContrato, ListItemContrato } from "./";
import { useSelectorContrato } from "./useSelectorContrato";

import { Dialog, Transition } from "@headlessui/react";
import { TripleMaze } from "react-spinner-animated";

export const SelectorContrato = ({
    contratoKey,
    onAddArticle,
    objSelected,
}) => {
    const {
        open,
        alerta,
        setOpen,
        loading,
        columns,
        setAlerta,
        cancelButtonRef,
        sortConfig,
        getArticulos,
        selectArticle,
        setSortConfig,
        filterResult,
        busqueda,
        setBusqueda,
    } = useSelectorContrato(contratoKey);

    const { url, headers, user } = useContext(UserContext);

    const initialSteps = [
        {
            id: "1",
            name: "Seleccionar item",
            href: "#",
            status: "current",
        },
        {
            id: "2",
            name: "Opciones del item",
            href: "#",
            status: "upcoming",
        },
    ];

    const [steps, setSteps] = useState(initialSteps);

    const [activeStep, setActiveStep] = useState(steps[0]);

    const handleBack = () => {
        const index = steps.findIndex((x) => x.id === activeStep.id);
        setActiveStep(steps[index - 1]);

        switch (activeStep.id) {
            case "3":
                setSteps([
                    {
                        id: "1",
                        name: "Seleccionar item",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Opciones del item",
                        href: "#",
                        status: "current",
                    },
                ]);
                break;
            default:
                setSteps(initialSteps);
        }
    };

    const handleNext = (obj) => {

        if (steps[steps.length - 1].id === activeStep.id) {
            // console.log(obj);
            onAddArticle(obj);
            setOpen(false);
        } else {
            const index = steps.findIndex((x) => x.id === activeStep.id);
            setActiveStep(steps[index + 1]);
            switch (activeStep.id) {
                case "1":
                    setSteps([
                        {
                            id: "1",
                            name: "Seleccionar item",
                            href: "#",
                            status: "complete",
                        },
                        {
                            id: "2",
                            name: "Opciones del item",
                            href: "#",
                            status: "current",
                        },
                    ]);
                    break;
                default:
                    setSteps(initialSteps);
                    break;
            }
        }
    };

    useEffect(() => {
        if (!open) {
            setActiveStep(steps[0]);
            setSteps(initialSteps);
        }
    }, [open]);

    const closeSelectorContrato = () => {
        setOpen(!open);
    };

    return (
        <>
            <button
                type="button"
                onClick={getArticulos}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
            >
                Buscar artículo
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center p-4 text-center sm:items-start sm:p-0 sm:mt-5">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 text-left shadow-xl transition-all sm:w-full sm:max-w-6xl sm:p-6  ">
                                    <div className="absolute top-0 right-0 hidden pt-5 pr-6 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={closeSelectorContrato}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>

                                    {/* <Alert /> */}
                                    <nav aria-label="Progress" className="mt-8">
                                        <ul className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                                            {steps.map((step, stepIdx) => (
                                                <li
                                                    key={step.name}
                                                    className="relative md:flex md:flex-1"
                                                >
                                                    {step.status ===
                                                    "complete" ? (
                                                        <div className="group flex w-full items-center">
                                                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6 text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="ml-4 text-sm font-medium text-gray-900">
                                                                    {step.name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ) : step.status ===
                                                      "current" ? (
                                                        <div className="flex items-center px-6 py-4 text-sm font-medium">
                                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                                                <span className="text-indigo-600">
                                                                    {step.id}
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 text-sm font-medium text-indigo-600">
                                                                {step.name}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="group flex items-center">
                                                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                                    <span className="text-gray-500 group-hover:text-gray-900">
                                                                        {
                                                                            step.id
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                                    {step.name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    )}

                                                    {stepIdx !==
                                                    steps.length - 1 ? (
                                                        <>
                                                            {/* Arrow separator for lg screens and up */}
                                                            <div
                                                                className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                                                aria-hidden="true"
                                                            >
                                                                <svg
                                                                    className="h-full w-full text-gray-300"
                                                                    viewBox="0 0 22 80"
                                                                    fill="none"
                                                                    preserveAspectRatio="none"
                                                                >
                                                                    <path
                                                                        d="M0 -2L20 40L0 82"
                                                                        vectorEffect="non-scaling-stroke"
                                                                        stroke="currentcolor"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>

                                    <div className="h-96 max-h-full">
                                        {loading ? (
                                            <TripleMaze
                                                centered={true}
                                                width={"100px"}
                                                height={"200px"}
                                                text={"Espere..."}
                                            />
                                        ) : activeStep.id === "1" ? (
                                            <ListItemContrato
                                                articulos={filterResult}
                                                selectArticle={selectArticle}
                                                columns={columns}
                                                nextStep={handleNext}
                                                sortConfig={sortConfig}
                                                setSortConfig={setSortConfig}
                                                busqueda={busqueda}
                                                setBusqueda={setBusqueda}
                                            />
                                        ) : (
                                            activeStep.id === "2" && (
                                                <OptionsItemContrato
                                                    articulos={filterResult}
                                                    nextStep={handleNext}
                                                    prevStep={handleBack}
                                                />
                                            )
                                        )}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
