import React from "react";
import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { useActions } from "../../permissions/useActions";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Item = ({ to, svg, text, openSidebar, children, action }) => {
    const { operationBrowser, selectorBrowser } = useActions();
    return (
        <>
            <div className="pt-2">
                {!children ? (
                    <NavLink
                        to={to}
                        className={({ isActive }) =>
                            isActive
                                ? "group flex items-center px-2 py-2 text-sm font-medium border-l-2 border-white bg-gray-900 text-white no-underline"
                                : "group flex items-center px-2 py-2 text-sm font-medium  text-gray-300 hover:bg-gray-700 hover:text-white no-underline"
                        }
                    >
                        <div className="ml-1">{svg}</div>
                        {openSidebar ? null : <p className="-my-1">{text}</p>}
                    </NavLink>
                ) : (
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="w-full group flex items-center px-2 py-2 text-sm font-medium  text-gray-300 hover:bg-gray-700 hover:text-white no-underline">
                                    <div className="ml-1">{svg}</div>
                                    {openSidebar ? null : (
                                        <>
                                            <p className="-my-1">{text}</p>
                                            <svg
                                                className={`ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-200 ease-in-out group-hover:text-gray-400 ${
                                                    open
                                                        ? " rotate-90 transform duration-200 ease-in-out"
                                                        : "text-gray-300"
                                                }`}
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M6 6L14 10L6 14V6Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                    {children.map((subitem) => (
                                        <NavLink
                                            key={subitem.to}
                                            to={subitem.to}
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "group flex items-center px-2 py-2 text-sm font-medium border-l-2 border-white bg-gray-900 text-white no-underline"
                                                    : "group flex items-center px-2 py-2 text-sm font-medium  text-gray-300 hover:bg-gray-700 hover:text-white no-underline"
                                            }
                                        >
                                            <div className="ml-1">
                                                {subitem.svg}
                                            </div>

                                            <p className="-my-1">
                                                {subitem.text}
                                            </p>
                                        </NavLink>
                                    ))}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                )}
            </div>
        </>
    );
};

export default Item;
