//custom hook
import { useSelectorGenerico } from "./useSelectorGenerico";

//components
import { Table, TableFormat } from "../../TableComponent";
import { Alert } from "../../Alerts";

//assets
import { TripleMaze } from "react-spinner-animated";

export const SelectorGenerico = ({
    view = "grid",
    ruta,
    title,
    filter,
    id = [],
    extraArgument
}) => {
    const {
        data,
        loading,
        allColumns,
        messageAlert,
        sortConfig,
        applyChanges,
        saveChanges,
        setAllColumns,
        defaultSetting,
        setSortConfig,
        busqueda,
        acciones,
        setBusqueda,
        filterResult,
        paginationFinish,
    } = useSelectorGenerico(ruta, filter, id, extraArgument);

    return (
        <>
            <Alert />
            <div className="mt-8 flex flex-col w-full px-6 h-full">
                <div className="inline-flex items-center justify-between mb-5">
                    <h1 className="text-2xl tracking-tight font-semibold text-gray-800">
                        {title}
                    </h1>
                    <div className="max-w-lg w-full lg:max-w-xs">
                        <label htmlFor="search" className="sr-only">
                            Busqueda
                        </label>
                        <div className="relative rounded-md shadow-sm justify-end">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </div>
                            <input
                                type="search"
                                value={busqueda}
                                onChange={(e) => setBusqueda(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Búsqueda inteligente"
                            />
                        </div>
                    </div>
                    <TableFormat
                        messageAlert={messageAlert}
                        allColumns={allColumns}
                        setAllColumns={setAllColumns}
                        ruta={ruta}
                        onApplyChanges={applyChanges}
                        onSaveChanges={saveChanges}
                        onDefaultWebForm={defaultSetting}
                    />
                </div>
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden md:rounded-lg">
                            {loading ? (
                                <div className="h-40">
                                    <TripleMaze
                                        centered={true}
                                        height={"250px"}
                                        text={"Cargando..."}
                                    />
                                </div>
                            ) : filterResult ? (
                                <Table
                                    data={filterResult}
                                    column={allColumns}
                                    view={view}
                                    filtroSelect={id}
                                    acciones={acciones}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                    busqueda={busqueda}
                                    paginationFinish={paginationFinish}
                                />
                            ) : (
                                <div className="w-full py-4 px-2 mt-20">
                                    <p className="text-gray-400 italic text-center">
                                        No hay datos para esta busqueda
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
