import { useContext } from "react";
import UserContext from "../../context/UserContext";

export const WorkstationSelection = () => {
    const { user, setWorkstationActive, workstationActive } =
        useContext(UserContext);

    const asignWorkstation = (workstationObject) => {
        setWorkstationActive(workstationObject);
        localStorage.setItem("workstation", JSON.stringify(workstationObject));
    };

    return (
        <>
            <div className="w-full">
                <h2 className="py-8 font-semibold text-center leading-9 w-full">
                    {workstationActive === null
                        ? "Asigne la estación de trabajo para comenzar a trabajar."
                        : "Estación de trabajo actual: " + workstationActive.Nombre}
                </h2>
                <div className="flex items-center justify-center w-full h-fit">
                    <div className="grid grid-cols-3 gap-4 place-items-stretch h-56">
                        {user.Workstations.map((workstation, i) => (
                            <div
                                key={i}
                                className="transition duration-700 ease-in-out transform hover:scale-110 px-2 text-xs leading-5 font-semibold rounded-full"
                            >
                                <button
                                    onClick={() =>
                                        asignWorkstation(workstation)
                                    }
                                >
                                    <svg
                                        className="w-28 text-gray-600 hover:text-gray-800"
                                        viewBox="0 0 128 128"
                                    >
                                        <path
                                            fill="#B7D5E5"
                                            d="M71.59 68.58H58.75c0 20.36-4.21 28.52-4.21 28.52H75.8c0-.01-4.21-8.17-4.21-28.52z"
                                        ></path>
                                        <path
                                            fill="#2F7889"
                                            d="M39.41 98.17c-.07-1.14.1-2.64.1-2.64s45.24-1.88 48.83-.09c0 0 .48 1.66.15 2.74c-.79 2.53-10.99 4.8-24.55 4.8s-24.37-2.16-24.53-4.81z"
                                        ></path>
                                        <ellipse
                                            cx="64"
                                            cy="95.96"
                                            fill="#B7D5E5"
                                            rx="24.55"
                                            ry="4.8"
                                        ></ellipse>
                                        <path
                                            fill="#69A1BA"
                                            d="M71.59 68.58H58.75c0 6.64-.45 11.98-1.05 16.16l15.31 2.3c-.78-4.47-1.42-10.54-1.42-18.46z"
                                        ></path>
                                        <path
                                            fill="#EEE"
                                            d="M55.49 91.46s-3.72 1.52-2.88 2.63c1.3 1.74 4.93 2.83 13.71 3.42c6.17.41 3.75 2.13-2.05 2.48c-4.07.24-23.58.4-24.74-3.67c-.94-3.31 13.18-4.86 15.96-4.86zm43.91 15.05H28.49c-.97 0-1.94.54-2.48 1.4l-7.78 10.7c-.72.98-.01 2.36 1.2 2.36h89.13c1.22 0 1.92-1.38 1.2-2.36l-7.88-10.8c-.53-.76-1.5-1.3-2.48-1.3z"
                                        ></path>
                                        <path
                                            fill="#69A1BA"
                                            d="M35.61 111.26h-4.86l.65-1.18h4.86zm7.67 0h-4.86l.54-1.18h5.07zm7.34 0h-4.97l.76-1.18h4.85zm7.23 0h-4.86l.76-1.18h4.64zm7.23 0h-4.97l.44-1.18h4.1zm7.23 0h-4.86l-.54-1.18h4.65zm7.56 0H74.9l-.86-1.18h4.86zm7.23 0h-4.97l-.86-1.18h4.97zm9.82 0h-7.45l-.86-1.18h7.56zm-3.78 1.95H98l-.86-1.3h-4.97zm-7.98 0h4.85l-.97-1.3h-4.86zm-7.34 0h4.85l-.75-1.3h-4.97zm-7.88 0h4.85l-.86-1.3h-4.64zm-7.02 0h4.86l-.54-1.3h-4.1zm-7.02 0h4.86l.33-1.3h-4.75zm-7.66 0h4.86l.65-1.3H49zm-8.63 0h4.96l.76-1.3h-4.97zm-9.93 0h7.34l.75-1.3h-7.45zm46.41 1.94h4.96l-.86-1.3h-4.96zm-7.77 0h4.85l-.64-1.3h-4.64zm-9.29 0H64l-.11-1.3h-4.42zm-8.74 0h4.97l.43-1.3h-4.75zm-9.17 0h4.85l.76-1.3h-4.86zm-12.42 0h9.83l.75-1.3h-9.82zm55.7 0h14.68l-.86-1.3H83.54zm11.12 1.94h4.96l-.97-1.29h-4.96zm-9.07 0h4.86l-.97-1.29h-4.86zm-39.18 0h33.35l-.75-1.29H48.02zm-10.47 0h4.96l.54-1.29h-4.75zm-9.28 0h4.85l.54-1.29h-4.75z"
                                            opacity=".57"
                                        ></path>
                                        <path
                                            fill="#2F7889"
                                            d="M108.69 124H19.31c-.88 0-1.64-.76-1.64-1.62v-2.4h92.68v2.4c-.01.86-.78 1.62-1.66 1.62z"
                                        ></path>
                                        <path
                                            fill="#B7D5E5"
                                            d="M99.4 107.52c.61 0 1.3.36 1.67.88l8.22 11.27c.05.06.05.13.02.2c-.04.07-.09.1-.17.1H18.86c-.08 0-.13-.03-.17-.1a.166.166 0 0 1 .02-.2l8.12-11.16l.02-.03l.02-.03c.35-.57.99-.93 1.63-.93h70.9m0-1.01H28.49c-.97 0-1.94.54-2.48 1.4l-8.12 11.16c-.57.79-.01 1.9.97 1.9h90.29c.98 0 1.54-1.11.97-1.9l-8.22-11.27c-.55-.75-1.52-1.29-2.5-1.29z"
                                        ></path>
                                        <path
                                            fill="#69A1BA"
                                            d="M113.46 79.66H14.54a3.44 3.44 0 0 1-3.44-3.44v-67c0-1.9 1.54-3.44 3.44-3.44h98.91c1.9 0 3.44 1.54 3.44 3.44v67c.01 1.9-1.53 3.44-3.43 3.44z"
                                        ></path>
                                        <path
                                            fill="#B7D5E5"
                                            d="M113.46 77.89H14.54a3.44 3.44 0 0 1-3.44-3.44v-67c0-1.9 1.54-3.44 3.44-3.44h98.91c1.9 0 3.44 1.54 3.44 3.44v67a3.431 3.431 0 0 1-3.43 3.44z"
                                        ></path>
                                        <radialGradient
                                            id="notoDesktopComputer0"
                                            cx="46.465"
                                            cy="-5.176"
                                            r="60.973"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                offset="0"
                                                stopColor="#2F7889"
                                            ></stop>
                                            <stop
                                                offset="1"
                                                stopColor="#424242"
                                            ></stop>
                                        </radialGradient>
                                        <path
                                            fill="url(#notoDesktopComputer0)"
                                            d="M15.25 9.38h97.5V72.5h-97.5z"
                                        ></path>
                                        <path
                                            fill="none"
                                            stroke="#EEE"
                                            strokeLinecap="round"
                                            strokeMiterlimit="10"
                                            strokeWidth="3.014"
                                            d="M16.39 6.99h11.19"
                                        ></path>
                                    </svg>
                                    <p>{workstation.Nombre}</p>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
