import { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";

//context
import UserContext from "../../../context/UserContext";
import AlertContext from "../../../context/AlertContext";

//reducer
import { filterReducer } from "../../Filters/filterReducer";

var rutaOperacion = "ordencompracliente";

export const useOrdenCompraCliente = () => {
    const { url, workstationActive, headers, user } = useContext(UserContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const [ordenCompraClienteData, setOrdenCompraClienteData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [queryObj, dispatchFilterReducer] = useReducer(filterReducer, []);

    const [allColumns, setAllColumns] = useState([]);
    const [filtros, setFiltros] = useState([]);
    const [acciones, setAcciones] = useState([]);
    const [orderColumn, setOrderColumn] = useState([]);
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'})

    //funcion para peticionar por 1era vez los datos con Contexto
    const getContextAndData = async () => {
        setLoading(true);
        await axios
            .post(url + rutaOperacion + "/readall", {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                Filtros: queryObj,
                RequiereContexto: true,
            })
            .then(function (res) {
                // console.log(res.data);
                setLoading(false);
                setOrdenCompraClienteData(res.data.Data);
                setAllColumns(res.data.WebForms);
                setAcciones(res.data.Acciones);
                setFiltros(res.data.Filtros);
                setOrderColumn(res.data.OrderByView);
            })
            .catch(function (error) {
                if (error.response != undefined) {
                    // console.log(error.response);
                    setLoading(false);
                    setOrdenCompraClienteData([]);
                    if (error.response.status === 404) {
                        setResponseText(
                            "Error de petición. Consulte con administración"
                        );
                    } else {
                        setResponseText(error.response.data.Motivo);
                    }
                    setStatusCode(error.response.status);
                } else {
                    setLoading(false);
                    setOrdenCompraClienteData([]);
                    setResponseText(
                        "Hubo un error crítico. Consulte con administración"
                    );
                }
            });
    };

    //funcion para refrescar la grilla sin Contexto
    const getOrdenCompraClienteData = async () => {
        setLoading(true);
        await axios
            .post(url + rutaOperacion + "/readall", {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                Filtros: queryObj,
            })
            .then(function (response) {
                setLoading(false);
                // console.log(response.data);
                setOrdenCompraClienteData(response.data.Data);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    //funcion que se ejecuta cuando se cierra el sidebar de filtros
    const closeFiltro = () => {
        const filtrosAplicados = filtros.map((f) => {
            if (f.displayvalue === "") {
                const action = {
                    type: "clearFilter",
                    payload: { filterName: "bySelect_" + f.viewField },
                };
                dispatchFilterReducer(action);
            }
            return f;
        });
        setFiltros(filtrosAplicados);
        applyFilters();
    };

    //funcion que se ejecuta cuando se aplica el boton de guardar en el sidebar de filtros
    const applyFilters = async () => {
        const validateFilters = queryObj.filter((newFilter) => {
            return newFilter.displayvalue !== "";
        });
        dispatchFilterReducer({ type: "validateFilters" });
        const filtrosActualizados = filtros.map((filtro) => {
            const coincidencia = validateFilters.find(
                (query) => query.filterField === filtro.viewField
            );
            if (coincidencia) {
                if (coincidencia.filterType === "byDateRange") {
                    return {
                        ...filtro,
                        aplicado: true,
                        filtervalue: `${coincidencia.desde};${coincidencia.hasta}`,
                    };
                } else {
                    return {
                        ...filtro,
                        aplicado: true,
                    };
                }
            } else {
                return { ...filtro, aplicado: undefined }; // Sin modificaciones si no hay coincidencia
            }
        });

        setFiltros(filtrosActualizados);
        setLoading(true);
        await axios
            .post(url + rutaOperacion + "/readall", {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                Filtros: validateFilters,
            })
            .then(function (response) {
                setLoading(false);
                // console.log(response.data);
                setOrdenCompraClienteData(response.data.Data);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    //funcion que aplica las columnas
    const applyChanges = (e) => {
        setAllColumns([...e]);
    };

    //funcion que guarda las columnas
    const saveChanges = async (dataIncoming) => {
        const dataColumn = dataIncoming.map((item, index) => {
            var newData = {
                UsuarioKey: user.UsuarioKey,
                WebFormDefaultKey: item.WebFormDefaultKey,
                FieldWidth: item.FieldWidth,
                Visible: item.Visible,
                Orden: index + 1,
            };
            return newData;
        });

        console.log(dataColumn);

        await axios
            .post(url + `webformusuario/update/${rutaOperacion}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                Data: dataColumn,
            })
            .then(function (response) {
                console.log(response);
                setAllColumns(response.data);
                setMessageAlert(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //funcion que setea por default las columnas
    const defaultSetting = (e) => {
        setAllColumns([...e]);
        setMessageAlert(true);
        setTimeout(() => {
            setMessageAlert(false);
        }, 500);
    };

    //funcion que guarda el ordenamiento seleccionado
    const applyOrderColumn = async(e) => {
        await axios
            .post(url + `webformusuario/orderbyview/update/${rutaOperacion}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                orderbyview: e,
            })
            .then(function (response) {
                if (response.status === 200) {
                    getContextAndData();
                    setOrderColumn(e);
                }
                console.log(response);
                // setAllColumns(response.data);
                // setMessageAlert(true);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }

    //useEffects
    useEffect(() => {
        getContextAndData(); //pide la data al iniciar el componente
        
    }, []);

    useEffect(() => {
        // revisa los filtros y les agrega su respectivo display value
        const nuevosFiltros = filtros.map((filtro) => {
            const filtroResultado = queryObj.find(
                (objF) => objF.filterField === filtro.viewField
            );
            // console.log(filtroResultado);
            if (filtroResultado) {
                if (filtroResultado.filterType === "byDateRange") {
                    // es fecha
                    filtro.displayvalue = `${filtroResultado.desde} / ${filtroResultado.hasta}`;
                } else {
                    // todos los demas
                    filtro.displayvalue = filtroResultado.displayvalue;
                }
            } else {
                filtro.displayvalue = "";
            }

            return filtro;
        });
        setFiltros(nuevosFiltros);
    }, [queryObj]);

    return {
        loading,
        allColumns,
        orderColumn,
        rutaOperacion,
        messageAlert,
        applyOrderColumn,
        ordenCompraClienteData,
        applyChanges,
        saveChanges,
        defaultSetting,
        queryObj,
        dispatchFilterReducer,
        filtros,
        getOrdenCompraClienteData,
        acciones,
        applyFilters,
        closeFiltro,
        sortConfig,
        setSortConfig,
    };
};
