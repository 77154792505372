import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { TripleMaze } from "react-spinner-animated";

import { Table } from "../../TableComponent";

import { useSelectorArticulo } from "./useSelectorArticulo";

export const SelectorArticulo = ({ onAddArticle, listaPrecioKey = 1 }) => {
    const {
        open,
        alerta,
        busqueda,
        setBusqueda,
        setOpen,
        loading,
        setAlerta,
        allColumns,
        getArticulos,
        selectArticle,
        cancelButtonRef,
        sortConfig,
        setSortConfig,
        filterResult,
        close
    } = useSelectorArticulo(onAddArticle, listaPrecioKey);

    return (
        <>
            <button
                type="button"
                onClick={getArticulos}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
            >
                Buscar artículo
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-5/6 sm:p-6">
                                    <div>
                                        {alerta && (
                                            <div className="rounded-md bg-yellow-50 p-4 absolute top-9 left-80 sm:w-2/6 z-20 ">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-yellow-500"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-yellow-800">
                                                            {alerta}
                                                        </p>
                                                    </div>
                                                    <div className="ml-auto pl-3">
                                                        <div className="-mx-1.5 -my-1.5">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    setAlerta(
                                                                        ""
                                                                    )
                                                                }
                                                                className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none"
                                                            >
                                                                <span className="sr-only">
                                                                    Close
                                                                </span>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-3 text-left sm:mb-5 flex justify-between items-center">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl leading-6 text-gray-900 font-semibold "
                                            >
                                                Listado de artículos
                                            </Dialog.Title>

                                            <div className="max-w-lg w-full lg:max-w-xs">
                                                <label
                                                    htmlFor="search"
                                                    className="sr-only"
                                                >
                                                    Busqueda
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm justify-end">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="search"
                                                        value={busqueda}
                                                        onChange={(e) =>
                                                            setBusqueda(
                                                                e.target.value
                                                            )
                                                        }
                                                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Búsqueda inteligente"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {loading ? (
                                            <div className="h-40">
                                                <TripleMaze
                                                    centered={true}
                                                    height={"250px"}
                                                    text={"Cargando..."}
                                                />
                                            </div>
                                        ) : (
                                            <Table
                                                data={filterResult}
                                                column={allColumns}
                                                view={"single"}
                                                sortConfig={sortConfig}
                                                setSortConfig={setSortConfig}
                                                busqueda={busqueda}
                                            />
                                        )}
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                            onClick={selectArticle}
                                        >
                                            Seleccionar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={close}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
