import { useContext, useState, useEffect } from "react";
import Moment from "moment";
import FilterContext from "../../context/FilterContext";

export const FilterByInputRangeGeneric = ({
    title,
    viewField,
    tipoFiltro,
    inicioDefault = 0,
    finDefault = 100000,
    displayvalue,
    operacion,
    inicial,
    aplicado
}) => {
    const { dispatchFilterReducer } = useContext(FilterContext);
    const fechaString = displayvalue;

    // Dividir la cadena por el carácter '/'
    const fechasArray = fechaString.split("/").map((fecha) => fecha.trim());

    const fechainicio = fechasArray[0];
    const fechafin = fechasArray[1];

    const [inicio, setInicio] = useState(
        tipoFiltro === 'byDateRange' ? Moment(fechainicio).format("YYYY-MM-DD")
                                     : inicioDefault
    );  

    const [fin, setFin] = useState(
        tipoFiltro === 'byDateRange' ? Moment(fechafin).format("YYYY-MM-DD")
                                     : finDefault
    );

    useEffect(() => {
        if(inicial === 1){
            const action = {
                type:'addOrUpdateFilter',
                payload:{
                    filterName:'byInputRange_'+ viewField,
                    filterType:tipoFiltro, 
                    filterField:viewField,
                    operacion:operacion, 
                    desde:inicio, 
                    hasta:fin
                },
            };
    
            dispatchFilterReducer(action);   
        }

    }, [inicio, fin]);
    
    const handleInputDesdeChange = ({target}) => {
        setInicio(target.value);
    };

    const handleInputHastaChange = ({target}) => {
        setFin(target.value);
    };

    return (
        <div className="mt-4">
            <label className="text-sm font-medium text-gray-900">
                {title}
            </label>
            <div className="inline-flex items-center space-x-2 pt-2">
                <div className="w-1/2 min-w-0 flex-1">
                    <input
                        type={ tipoFiltro === 'byDateRange' ? "date" : "text"}
                        value={inicio}
                        onChange={handleInputDesdeChange}
                        className="relative block w-full rounded-none rounded-tl-md rounded-bl-md border-gray-300 bg-transparent focus:z-10 sm:text-xs"
                    />
                </div>
                <span className="w-4 text-gray-600 bg-gray-500 h-1"></span>
                <div className="min-w-0 flex-1">
                    <input
                        type={ tipoFiltro === 'byDateRange' ? "date" : "text"}
                        value={fin}
                        onChange={handleInputHastaChange}
                        className="relative block w-full rounded-none rounded-tr-md rounded-br-md border-gray-300 bg-transparent focus:z-10 sm:text-xs"
                    />
                </div>

            </div>
        </div>
    );
    
};
